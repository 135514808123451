<template>
    <div class="comp flex-df flex-direction-c" id="ygh-content-box">
        <tab />
        <div class="list">
            <a-row :gutter="30" style="height: auto" class="list-1" type="flex">
                <a-col
                    :lg="auth_code == '003' ? 24 : 18"
                    :xl="auth_code == '003' ? 24 : 19"
                    :md="auth_code == '003' ? 24 : 17"
                    style="height: auto"
                >
                    <list />
                </a-col>
                <a-col v-if="auth_code != '003'" :lg="6" :xl="5" :md="7" style="height: auto">
                    <fastEntry @approval="(evt) => $refs.dialogApproval.open(evt)" />
                </a-col>
            </a-row>
        </div>
        <dialogHome />
        <dialogApproval ref="dialogApproval"></dialogApproval>
    </div>
</template>

<script>
import dialogHome from './dialogHome.vue'
import tab from './tab.vue'
// 快捷入口
import { pendingNumber } from '@/api/modular/fileStream/documentManage'
import { mapGetters } from 'vuex'
import dialogApproval from './components/dialogApproval.vue'
import fastEntry from './components/fastEntry.vue'
import list from './list.vue'
export default {
    name: 'home',
    components: { tab, list, dialogHome, fastEntry, dialogApproval },
    data() {
        return {
            items: [
                {
                    url: '',
                    text: '快速发文',
                    img: require('@/assets/img/ksfw.png'),
                    route: { path: '/fileStreamDocumentSend' },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    url: '',
                    text: '收文登记',
                    img: require('@/assets/img/swdj.png'),
                    route: { path: '/fileStreamDocumentManage' },
                    storeKey: 'bill/setRegistration',
                },
            ],
            pendingNumber: 0,
        }
    },
    computed: {
        ...mapGetters(['auth_code']),
    },
    mounted() {
        console.log(process.env.VUE_PRIVE_BASE_URL, 'process.env.VUE_PRIVE_API_BASE_UR', this.auth_code)
        this.initNumber()
    },
    methods: {
        fastTodo(o) {
            this.$store.commit(o.storeKey, true)
            this.$router.push(o.route)
        },
        initNumber() {
            pendingNumber()
                .then((res) => {
                    if (res.code === 200) {
                        this.pendingNumber = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}

.align-items {
    align-items: center;
}

.align-items-container {
    flex-direction: column;
    align-items: flex-end;
}
.list-1 {
    min-height: 690px;
}

.comp {
    width: 1280px;
    margin: auto;
    position: relative;

    .list {
        margin-top: 37px;
        // height: calc(100% - 200px);
        flex: 1;
        padding-bottom: 24px;

        .dwsh {
            width: 194px;
            height: 126px;
            background: #ffffff;
            border-radius: 4px 0px 0px 4px;
            padding: 36px 31px;

            .right {
                font-size: 18px;
                color: #ff9e01;
            }

            .pendingNumber {
                font-size: 30px;
                font-weight: 600;
            }
        }

        .img {
            width: 50px;
            height: 50px;
        }

        .ksfw {
            width: 194px;
            background: #ffffff;
            border-radius: 4px 0px 0px 4px;
            margin-top: 20px;
            padding: 52px 31px;

            .item {
                .right {
                    font-size: 18px;
                    color: #3f3f3f;
                }
            }
        }

        .item:last-child {
            padding-top: 57px;
        }
    }
}
</style>
