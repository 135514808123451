<template>
  <div class="dialogCard" :style="{'background-image': `url(${item.url})`}">
    <div class="cardTitle">
      {{ item.title }}
    </div>
    <div class="cardText">
      {{ item.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dialogCard {
  width: 211px;
  height: 270px;
  background-size: 100% 100%;
  padding-top: 42px;
  margin-bottom: 49px;
  .cardTitle {
    text-align: left;
    font-size: 30px;
    font-family: FZLanTingHei-L-GBK;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1;
    padding-left: 30px;
  }
  .cardText {
    margin-top: 49px;
    font-size: 16px;
    font-family: FZLanTingHei-L-GBK;
    font-weight: 400;
    color: #4B4B4B;
    line-height: 32px;
    padding: 0 18px 0 26px;
    text-align: left;
  }
}
</style>
