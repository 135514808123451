<template>
    <div class="dialogContent">
        <template v-if="index">
            <dialogCard :item="card" v-for="(card, cardIndex) in cards" :key="cardIndex" />
        </template>
        <template v-else>
            <div class="item">
                <div class="label">公文登记与发送</div>
                <div class="images">
                    <img src="/images/billSend.png" alt="" />
                </div>
                <div class="descri">接收登记上级单位的发文 本单位公文发送到各室接收 接收情况反馈，提醒接收等等</div>
            </div>
            <div class="item">
                <div class="label">公文审批</div>
                <div class="images">
                    <img src="/images/approval.png" alt="" />
                </div>
                <div class="descri">
                    发起公文审批流程，支持发起人发起任意流程，支持审批后增加批示人与抄送人。自动推送审批与抄送消息，提醒处理等等
                </div>
            </div>
            <div class="item">
                <div class="label">单位互联</div>
                <div class="images">
                    <img src="/images/unitInterconnected.png" alt="" />
                </div>
                <div class="descri">支持跨企业发送到下级单位 （下级单位也上了这个系统）</div>
            </div>
        </template>
    </div>
</template>
<script>
import dialogCard from './dialogCard.vue'
export default {
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    components: {
        dialogCard,
    },
    data() {
        return {
            cards: [
                {
                    title: '更周全',
                    text: '一站式覆盖收文、发文、审批、标签设置等功能',
                    url: '/images/dialogCardFirst.png',
                },
                {
                    title: '好上手',
                    text: '零成本上手，简单易懂、看了就会的常规操作',
                    url: '/images/dialogCardSecond.png',
                },
                {
                    title: '可定制',
                    text: '我们提供了标准版，互联版，以及私有化版本根据需要自由选择',
                    url: '/images/dialogCardThree.png',
                },
            ],
        }
    },
}
</script>
<style lang="less" scoped>
.dialogContent {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    text-align: center;
    .item {
        width: 28%;
        .label {
            font-size: 20px;
            color: #4b4b4b;
        }
        .images {
            width: 150px;
            height: 150px;
            font-size: 0;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .descri {
            font-size: 12px;
            color: #4b4b4b;
            line-height: 24px;
        }
    }
}
</style>
