<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <div>
      <form-box
        :isDisabled="isDisabled"
        :todoType="todoType"
        :officialId="officialId"
        :randomCode="randomCode"
        @close="close"
        ref="box"
      />
    </div>
  </full-model-view>
</template>

<script>
import { Ellipsis, FullModelView } from '@/components'
import formBox from '@/views/fileStream/documentManage/fromBox.vue'
export default {
  components: {
    Ellipsis,
    FullModelView,
    formBox,
  },
  data() {
    return {
      title: '新增',
      subTitle: '收文登记',
      isDisabled: false, // 是否可编辑
      visible: false,
      todoType: '',
      officialId: '',
      randomCode: null, // 乱码 保证每次进入请求最新数据
    }
  },
  methods: {
    open({ type, id }) {
      this.isDisabled = false
      this.visible = true
      if (type === 'approval') {
        this.officialId = id
        this.title = '发起审批'
        this.todoType = 'registration'
        this.randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box {
  margin: 0 auto;
  /*width: 1200px;*/
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block {
  margin: 0 auto;
}
.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
