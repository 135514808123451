<template>
  <a-modal v-model="visible" :closable="false" :maskClosable="false" :footer="null" :width="840" :title="null">
    <div class="dialogHome">
      <dialogTitle :index="index" />
      <dialogContent :index="index" />
      <div class="dialogFooter flex">
        <template v-if="index">
          <div class="flex btnCon">
            <span class="notTip" style="margin-right: 15px" @click="notTip">不再提醒</span>
            <a-button type="primary" @click="knowed">知道了</a-button>
          </div>
        </template>
        <a-button v-else type="primary" class="dialogNext" @click="index = 1">下一页</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { closePopup, getPopup } from '@/api/modular/fileStream/documentManage'
import { mapState } from 'vuex'
import dialogContent from './components/dialogContent.vue'
import dialogTitle from './components/dialogTitle.vue'
export default {
  data() {
    return {
      visible: false,
      index: 0,
    }
  },
  computed: {
    ...mapState({
      isShowGuide: (state) => state.bill.isShowGuide,
    }),
  },
  components: {
    dialogTitle,
    dialogContent,
  },
  mounted() {
    this.judgeIsShow()
  },
  methods: {
    knowed() {
      this.visible = false
      this.$store.commit('bill/setIsShowGuide', false)
    },
    judgeIsShow() {
      if (this.isShowGuide) {
        getPopup()
          .then((res) => {
            if (res.code === 200) {
              const { status } = res.data
              if (status === 0) {
                this.visible = true
              } else {
                this.visible = false
              }
              this.$store.commit('bill/setIsShowGuide', false)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.visible = false
      }
    },
    notTip() {
      closePopup({
        status: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.visible = false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.dialogHome {
  .dialogContent {
    justify-content: space-between;
    padding: 0 60px;
    text-align: center;
    .item {
      width: 150px;
      .label {
        font-size: 20px;
        color: #4b4b4b;
      }
      .images {
        width: 150px;
        height: 150px;
        font-size: 0;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .descri {
        font-size: 12px;
        color: #4b4b4b;
        line-height: 24px;
      }
    }
  }
  .dialogFooter {
    justify-content: flex-end;
    margin-top: 13px;
    .btnCon {
      align-items: center;
      .notTip {
        margin-right: 15px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #4a4a4a;
        line-height: 1;
        margin-right: 41px;
        cursor: pointer;
      }
    }
  }
}
</style>
